import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import facebook_add_calendar from '../../../../../../../common/src/assets/image/facebook_add_calendar.png';
import facebook_copy_address from '../../../../../../../common/src/assets/image/facebook_copy_address.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        containing birthdays and upcoming events imported from Facebook. To
        connect PrintableCal to Facebook, follow these steps:
      </p>
      <ol className="numbered">
        <li>
          <span>Click the </span>
          <strong>Add Calendar </strong>button on the{' '}
          <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources">
            Calendar Data Sources
          </Link>{' '}
          step and select the <strong>Online -&gt; Facebook</strong> option. The{' '}
          <strong>Add Calendar Source - Facebook</strong> window will appear.
          <br />
          <Image
            alt="Hello fellow human."
            src={facebook_add_calendar}
            style={{ width: 690 }}
          />
        </li>
        <li>
          Open{' '}
          <a
            href="https://www.facebook.com/events/calendar"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/events/calendar
          </a>
          .
        </li>
        <li>
          Facebook provides two calendars - <strong>Upcoming Events</strong> and{' '}
          <strong>Birthdays</strong>. The links for these calendars can usually
          be found near the bottom-right corner of the page. Right-click the
          link for the desired calendar.
        </li>
        <li>
          Copy the calendar URL by clicking the{' '}
          <strong>Copy link address</strong> command on the context menu.
          Depending on your web browser, this command might be named{' '}
          <strong>Copy shortcut</strong> or <strong>Copy Link Location</strong>.
          <br />
          <Image alt="" src={facebook_copy_address} style={{ width: 398 }} />
        </li>
        <li>
          After copying the link, the address field at the top of this window
          should be automatically filled. If that doesn't happen, right-click
          the address field and select <strong>Paste</strong> from the popup
          menu, or press <strong>Ctrl+V</strong>.
        </li>
        <li>
          The OK button will now be enabled. Click the OK button to add your
          Facebook calendar to PrintableCal's list of available calendar
          sources.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Facebook"
      commentsId="commentsplus_post_136_489"
      title="Print Facebook Birthdays and Events"
      description="PrintableCal can create printable calendars containing birthdays and upcoming events imported from Facebook."
      keywords="print birthdays, print events, print Facebook calendar, print Facebook events, print Facebook birthdays, import Facebook birthdays, printable calendar, import birthdays"
      content={content}
    />
  );
};

export default Documentation;
